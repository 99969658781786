.qr-scan {
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
}

.header-div {
    padding: 20px;
    border-bottom: 3px solid gainsboro;
}

.with_out4 {
    border-left: 6px solid #D20C14;
    border-bottom: 6px solid #D20C14;
    height: 76vh;
    overflow-y: auto;
    /* display: inherit; */
}

.Richtexterditor p:nth-child(1) {
    /* color: #D20C14; */
    /* font: normal normal medium 80px/94px Brandon Grotesque; */
    letter-spacing: 0px;
    font-size: 60px;
    opacity: 1;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.Richtexterditor p:nth-child(2) {
    color: #000000;
    /* font: normal normal medium 80px/94px Brandon Grotesque; */
    letter-spacing: 0px;
    font-size: 30px;
    opacity: 1;
    font-family: brandon-grotesque, sans-serif;
    font-style: normal;
    font-weight: 500;
}

.crop {
    /* border: 5px solid #555A60; */
    border-radius: 15px;
    opacity: 1;
    width: 420px;
    padding: 15px;
    background: #fff;
}

.wifi_desc {
    font-size: 32px;
    opacity: 1;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.wifi_sec {
    display: inline-block;
    background: #D20C14;
    padding: 10px;
    border-radius: 10px;
}

.wifi_sec h3 {
  padding: 18px 5px 0px 5px;
  font-family: 'Brandon-grotesque-reg';
  font-size: 54px;
  color: #fff;
}
.qrtowifi {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    /* align-items: flex-start; */
    display: flex;
    justify-content: center;
}
/* .word-break .col-md-12 .head_left .lable .four_six {
    color: #D20C14 !important;
} */
/* .word-break p {
    border: 1px solid red;
} */
.thanku_head2 {
    font-size: 80px !important;
    color: #D20C14;
    font-family: 'Brandon-grotesque-med' !important;
    font-weight: 400;
    font-style: normal;

}
.thanku_head3 {
    font-family: 'Brandon-grotesque-reg' !important;
    color: #000000 !important;
    font-weight: 400;
    font-style: normal;
    font-size: 60px !important;
    padding: 5px;

}
.host_bold{
    font-weight: bold;
    text-transform: capitalize;

}
.float_left{

    float: left;
  }

.qrcode1{
    margin-left: 70px;
}