.bgcolor {
    /* background-color: aliceblue !important; */
    /* border-bottom: 2px solid darkgray; */
}

.headstyle {
    padding-top: 20px;
    padding-bottom: 20px;
}

.eachbox {
    padding-top: 10px;
}

.eachinnerbox {
    width: 50%;
    float: left;
}

.input_box {
    /* width: 90%; */
    width: 100%;
    padding: 12px 20px;
    /* margin: 8px 0; */
    box-sizing: border-box;
    border: 2px solid #D20C14;
    border-radius: 4px;
}

.check_height {
    width: 24px;
    height: 24px;
}

.sub_button {
    width: 170px;
    background: #555A60 0% 0% no-repeat padding-box;
    opacity: 1;
    border: none;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    /* margin: 4px 2px; */
    cursor: pointer;
    /* border-radius: 5px; */
}

.sub {
    padding-left: 22px;
    margin-bottom: 100px;
}

.sub2 {
    padding-left: 22px;
}

.label_own {
    /* padding: 12px 0px; */
    /* margin: 8px 0; */
    font-size: 30px;
    color: #555A60;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.label_own2 {
    /* padding: 12px 0px; */
    /* margin: 8px 0; */
    font-size: 24px;
    color: #555A60;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
    text-align: left;
}

.align_item {
    align-items: center;
    display: flex;
}

.firstrow {
    border-bottom: 2px solid lightgray;
}


/* .lastrow{
    border-top: 2px solid lightgray;
} */

.align_intial {
    text-align: right;
}

.align_self {
    align-items: center;
    display: flex;
}

.error_msg {
    width: 100%;
    float: left;
    text-align: left;
}

.error_msg {
    color: #D20C14;
    margin-bottom: 0px;
    font-size: 26px;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.with_out {
    border-left: 6px solid #D20C14;
    border-bottom: 6px solid #D20C14;
    height: 76vh;
    overflow-y: auto;
}


/* .check_height:checked,  .check_height:visited, .check_height:focus, .check_height:active {
    background-color: red !important;
}
input[type=checkbox]:checked  {
  
  background-color: red !important;
}  */

.four_six {
    font-size: 46px;
    color: #000000;
    font-family: 'Brandon-grotesque-bld';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 500;
    letter-spacing: 2px;
    /* padding-right: 225px; */
    text-transform: capitalize;

}

.star {
    color: #D20C14;
}


/* input[type="checkbox" i] {
    background-color: green !important;
}
.check_height::after {
background-color: red !important;
}
.cust_check input:checked {
  background-color: red !important;
} */


/* input[type="checkbox"] + .check {
    position: absolute;
    border-radius: 0px;
    left: 1px;
    top: 3px;
    border: solid 1px #eaeaea;
    background-image: url("../../Static/images/tick.png");
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: 3px 2px;
    background-size: 12px 12px;
} */

.container_label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 30px;
}


/* Hide the browser's default checkbox */

.container_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 3px;
    left: -28px;
    height: 35px;
    width: 35px;
    background-color: #fff;
    border: 1px solid lightgray;
}

/* .checkmark:hover {
    background-color: #fff !important;
    border: 1px solid lightgray !important;
} */

.container_label:hover {
background-color: none !important;
}
/* On mouse-over, add a grey background color */

/* .container_label:hover input~.checkmark {
    background-color: #ccc;
} */


/* When the checkbox is checked, add a blue background */

.container_label input:checked~.checkmark {
    background-color: #D20C14;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container_label input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container_label .checkmark:after {
    left: 11px;
    top: 3px;
    width: 14px;
    height: 22px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
#appointment-form {
    margin-top: 70px;
}
.label_height {
    height: 90px;
}