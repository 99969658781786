
.fullbox{
    margin-bottom: 30%;
}

.bgcolor{
  /* background-color: aliceblue !important; */
  /* border-bottom: 2px solid darkgray; */
  }
.headstyle{
  padding-top: 20px;
  padding-bottom: 20px;
}
.eachbox{
  padding-top: 10px;
  /* padding-left: 60px; */
}
.eachinnerbox{
  width: 50%;
  float: left;
}
.input_box{
  /* width: 90%; */
  width: 100%;
  padding: 18px 20px;
  /* margin: 8px 0; */
  box-sizing: border-box;
  /* border: 2px solid red; */
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 30px;
  /* font-family: brandon-grotesque, sans-serif; */
  font-style: normal;
  font-weight: 400;
  /* font-family: 'Brandon-grotesque-reg, sans-serif'; */
  /* font-family: 'Brandon-grotesque-reg'; */
  font-family: 'Brandon-grotesque-lit';
  color: #555A60 !important;
}

.input_box::placeholder {
  /* font-family: 'Brandon-grotesque-reg'; */
  font-family: 'Brandon-grotesque-lit';
  font-size: 30px;
  color: #555A60 !important;
  font-weight: lighter;
}
.check_height{
  width: 50px; 
  height: 40px; 
}
.sub_button{
  width: 200px;
  background: #555A60 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
  color: white;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  /* margin: 4px 2px; */
  cursor: pointer;
  height: 70px;
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  /* font-family: 'Brandon-grotesque-reg, sans-serif'; */
  /* border-radius: 5px; */
}
.for_img{
  width: auto;
  max-width: 100%;
}
.align-right_next {
      text-align: right;
    align-items: flex-end;
    display: block;
}
.with_out3 {
    border-left: 6px solid #D20C14;
    border-bottom: 6px solid #D20C14;
    height: 76vh;
    overflow-y: auto;
    display: inherit;
}
.del_sec_row {
  align-items: center;
  display: flex;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
}
.back-home-button{
  background: #D20C14 0% 0% no-repeat padding-box !important;

}