@font-face {
    font-family: 'Brandon-grotesque-reg';
    src: url('./Fonts/Brandon_reg.otf');
}
@font-face {
    font-family: 'Brandon-grotesque-bld';
    src: url('./Fonts/Brandon_bld.otf');
}
@font-face {
    font-family: 'Brandon-grotesque-med';
    src: url('./Fonts/Brandon_med.otf');
}
@font-face {
    font-family: 'Brandon-grotesque-reg_it';
    src: url('./Fonts/Brandon_reg_it.otf');
}
@font-face {
  font-family: 'Brandon-grotesque-lit';
  src: url('./Fonts/Brandon_lit.otf');
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#loading-icon {
  position: absolute;
  float: left;
  top: 40%;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  z-index: 15;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
