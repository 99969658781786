.selectVisitor {
    /* text-decoration: none;
color: white;
padding: 13px;
}
.div-selectVisitor{
    margin: auto; 
    width: 300px;
    background-color: red;
    margin-top: 25px;

}
.back{
    border: 1px solid red;
    color: black;
    padding: 10px;
    cursor: pointer;
}
.div-back{
width: 65px;
margin-left: 124px;
margin-top: 32px;
}
.check-in{
width: 50%;
text-align: center;
padding: 50px;
}
.selectVisitor a{
    text-decoration: none !important;
    color: white !important;
}
.div_checkout{
    width: 148px;
    background-color: dimgrey;
    margin-inline-start: auto;
}
.div_language{
    float: left;
}
.lang{
    border: 1px solid black;
    margin: auto;
    cursor: pointer;
}
.lang-space{
    margin-left: 150px;
}
.sb_button{
    width: 23%;
    background-color: red;
  border: none;
  color: white;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  /* border-radius: 10px; 
} */
    text-decoration: none;
    color: white;
    padding: 13px;
}

.div-selectVisitor {
    background-color: #D20C14;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 0px;
   
}

.back {
    border: 1px solid #D20C14;
    color: black;
    padding: 10px;
    cursor: pointer;
}

.div-back {
    width: 65px;
    margin-left: 124px;
    margin-top: 32px;
}

.check-in {
    width: 50%;
    text-align: center;
    padding: 50px;
}

.selectVisitor a {
    text-decoration: none !important;
    color: white !important;
}

.div_checkout {
    /* width: 148px;
  background-color: dimgrey; */
    margin-inline-start: auto;
    text-align: right;
}

.div_language {
    float: left;
}

.lang {
    border: 1px solid #555A60;
    /* margin: auto; */
    cursor: pointer;
    margin: 0px 5px;
    font-size: 22px;
}

.lang button {
    border: none;
    background: none;
    /* font-family: 'Brandon-grotesque-reg'; */
    font-family: brandon-grotesque, sans-serif;
    font-style: normal;
    font-weight: 400;
}

.lang:nth-child(1) {
    margin-left: 0px !important;
}

.lang-space {
    text-align: left;
}


/* .header_main {
  border-bottom: 1px solid gray;
} */


/* .footer_sec {
  border-top: 1px solid gray;
} */

.lang_list {
    list-style-type: none;
    display: flex;
    padding-left: 0px;
}

.lang_list li {
    padding: 10px;
}

.body_sec_visitor {
    height: 76vh;
    overflow-y: auto;
    align-items: center;
    display: flex;
}

.btn_select {
    background: #D20C14;
    border: none;
    /* padding: 40px 20px; */
    color: #fff;
    font-size: 36px;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
    width: 100%;
    min-height: 140px;
    padding: 10px;
}

.selectVisitor_check {
  width: 200px;
    border: 1px solid #555A60;
    background-color: #555A60;
    color: #fff;
    padding: 5px;
    font-size: 22px;
    height: 70px;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}

.wel_text {
    color: #D20C14;
    font-size: 72px;
}


/* .logo_sec {
  width: 150px;
} */

.visit_label {
    /* align-items: center;
  display: flex;
  padding-left: 175px; */
    height: 90px;
    align-items: center;
    display: flex;
    justify-content: center;
   
}


/* .visit_label {
  padding-left: 78px;
} */
.head_left{
  /* font-family: brandon-grotesque, sans-serif; */
  font-family: 'Brandon-grotesque-bld';
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  color: #000000;
  /* padding-left: 80px !important; */
}

.logo_sec.header {
    text-align: left;
    /* position: absolute;
  top: 25px; */
}
.time_sec.header {
    
    margin: auto;
    text-align: right;
    color: black;


}
.header_location {
    text-transform: capitalize;
    font-size: 33px;

}
/* .logo_sec {
 width: 60px;
    height: 80px;
} */
.body_sec_visitor {
    border-left: 6px solid #D20C14;
    border-bottom: 6px solid #D20C14;
}

.selected {
    /* border: 2px solid rgb(0, 0, 255); */
    background-color: #ffff;
    border: 2px solid #D20C14;
}

.selected_lang {
    /* color: white; */
}

.visit_label .head_left {
    padding-right: 75px;
}

.css-g1d714-ValueContainer {
    height: 75px;
}
.header_main {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
    background: #fff;
}
.div_center{
    justify-content: center;
}
/* .div_max_width_3 {
    max-width: 33.34% !important;
    padding:0px !important;
    margin-bottom: 41px !important;


}
.div_max_width_5 {
    max-width: 31% !important;
    padding:0px !important;
    flex: 0 0 30% !important;
} */
.div_max_width_5 button {
    width:100% !important;
    padding:0px !important;
    min-height:10rem !important;
}

.selected_lang:focus {
    box-shadow: none !important;
    border: none !important;
}
.selected_lang:focus {
    /* outline: -webkit-focus-ring-color auto 1px; */
    outline: none !important;
}

@media screen and (min-width:768px) and (max-width:1024px)
{
    .div_max_width_5 button
    {
            font-size:30px !important;
    }
}