.pdf-page {
    /* display: inline-block;
    background-color: white;
    /* width: 700px;
    height: 905.882px; */
    /* user-select: none;  */
}


/* .react-pdf__Page__canvas {
    height: 300px !important;
} */

.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
    display: initial !important;
    width: 100% !important;
    /* height: 400px !important; */
    overflow-y: auto;
    margin: 0px auto !important;
    overflow-x: hidden;
}


/* .with_out2 {
      border-left: 3px solid red;
    border-bottom: 3px solid red;
    height: 75vh;
    overflow-y: auto;
} */

.page_nation {
    font-size: 32px;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.pdf_btb {
    padding: 5px;
    width: 150px;
    height: 60px;
    font-size: 32px;
    color: #000000;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}
.react-pdf__Document{
    display: flex !important;
    overflow: hidden !important;
    align-content: flex-start !important;
    justify-content: center !important;
    height: auto !important;
}
