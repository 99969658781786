.loc-select{
    /* width: 30%;
    margin: auto; */
}
.loc_btn {
    border: none;
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 32px;
}
.err_msg {
    color: #D20C14;
}
.css-g1d714-ValueContainer {
    padding: 10px !important;
    font-size: 26px !important;
}