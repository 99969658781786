.safety_rules_img {
    width: 50%;
    margin: auto;
}

.accept_button {
    width: 200px;
    /* float: right; */
    border: none;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 22px;
    margin: 4px 2px;
    cursor: pointer;
    /* border-radius: 10px; */
    background: #555A60 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 70px;
    /* font-family: 'Brandon-grotesque-reg'; */
    font-family: brandon-grotesque, sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}

.react-pdf__Document,
.react-pdf__Page.pdf-page {
  /* height: 590px !important; */
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.react-pdf__Page__canvas {
    height: auto !important;
}


/* .react-pdf__Page__textContent {
    display: none;
} */
