.qr-scan-and-email {
    width: 50%;
    margin: auto;
}

.qr-scan {
    width: 300px;
    margin: auto;
}

.email {
    width: 80%;
    margin: auto
}

.vertical-middle {
    vertical-align: middle;
    margin: auto;
}

.or {
    font-size: 60px;
    color: #000000;
    font-family: 'Brandon-grotesque-reg'; /*NOSONAR*/
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.textcolor1 {
    /* text-align: left; */
    font-size: 44px;
    color: #000000;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.height_sixty {
    height: 70px !important;
    font-size: 28px;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
}

.error-message {
    color: #D20C14;
    margin-top: 16px;
    font-size: 26px;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
    text-align: left;
   }
   .error-message2 {
    color: #D20C14;
    margin-top: 16px;
    font-size: 26px;
    font-family: 'Brandon-grotesque-reg';
    /* font-family: brandon-grotesque, sans-serif; */
    font-style: normal;
    font-weight: 400;
    text-align: center;
    /* padding-right: 115px; */
   }
   .show_qr {
     color: #D20C14;  
     font-size: 44px;
     font-family: 'Brandon-grotesque-med';
     /* font-family: brandon-grotesque, sans-serif; */
     font-style: normal;
     font-weight: 500;
     text-align: center;
   }
   .nxt_btn {
       width: 100% !important;
       font-size: 38px !important; 
       height: 70px !important;
       font-family: 'Brandon-grotesque-reg';
       /* font-family: brandon-grotesque, sans-serif; */
       font-style: normal;
       font-weight: 400;
   }
   .red-col {
    color: #D20C14;
   }
   .qr-scan div {
    box-shadow: rgb(255 0 0 / 50%) 0px 0px 0px 12px inset !important;
   }
.header_main {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
}
.header_main1 {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
}
.display_inherit {
    display: inherit;
}
.qr_sec_img {
    width: 375px;
    /* margin-left:135px ; */
    box-sizing: border-box;
}
.qrcode_height_sec {
    height: 58vh;
}
.scan-error-msg{
    margin-left: 18%;
    text-align: left !important;
}